import React from 'react';

import Layout from '../components/Layout';

// import { Link } from 'gatsby';
import Sidebar from '../components/Sidebar';
import config from '../../config';
const IndexPage = () => (
  <Layout>
    <Sidebar />
    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="about"
      >
        <div className="w-100">
          <h1 className="mb-0">
            {config.firstName}
            <span className="text-primary">{config.lastName}</span>
          </h1>
          <div className="subheading mb-5">
            {config.address} · {config.phone} ·
            <a title={`mail to `+config.email} href={`mailto:${config.email}`}>{config.email}</a>
          </div>
          <p className="lead mb-5">
            I am a PhD student at University of Illinois Chicago studying computer
            science with software quality improvement being my primary research
            interest.  Since 2003, I have been CEO of MCS, an application development
            and IT service company, performing leadership and technical roles.  I
            also am an experienced professional managing and developing enterprise
            web application development projects using agile methodologies.
          </p>
          <div className="social-icons">
            {config.socialLinks.map(social => {
              const { icon, url } = social;
              return (
                <a title={url} key={url} href={url}>
                  <i className={`fab ${icon}`}></i>
                </a>
              );
            })}
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="experience"
      >
        <div className="w-100">
          <h2 className="mb-5">Experience</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">CEO</h3>
              <div className="subheading mb-3">MCS</div>
              <p>
                Since 2003, MCS has worked with businesses locally and remotely around North
                America to provide IT solutions. These include IT Services, Web Application
                Development, Web Design, Technology Product Sales, Hosting Services, and
                Telecommunications. MCS is able to fill almost any need for technology an
                organization may have.
              </p>

              <p>
                Responsibilities include management of office staff and providing the overall
                direction for the company. Ongoing responsibilities include project management,
                leadership of the programming team, software quality assurance testing, and
                application development.
              </p>

              <p><b>Enterprise Application Development (<i>industry: Biometric Services</i>)</b></p>
              <p>
                Have directly worked with this company since 2004 on projects relating to
                scheduling and fulfillment of background screening using biometric and
                demographic data. Currently provide ongoing maintenance on existing
                application and development of new components based on business need.
              </p>
              <p>
                Performed various roles for this project including developer, business analyst,
                quality assurance tester, and project manager. Currently, my role is project
                management to support application development team, mentoring team members,
                and application development as needed.
              </p>
              <p>
                Project spans across multiple departments to support the background screening
                process and includes the following items.
              </p>
              <ul>
                <li>Public website available to applicants for scheduling appointments including collection and validation of biographical, demographic, and other data required to complete the screening process.</li>
                <li>Internal interface for operators to schedule appointment and assist with customer requests to support requirements of an internally staffed call center.</li>
                <li>Integration with multiple third party systems for validation of applicant data and applicant eligibility.</li>
                <li>Accounting functions and reports are available for finance to record activity and maintain customer accounts.</li>
                <li>Integration to biometric capture equipment to provide needed data for completing of the appointment and response information.</li>
                <li>Integration with AFIS system to manage transmission of biometric information and applicant data.</li>
              </ul>

              <p><b>Zero Day Virus Detection & Mitigation (<i>industry: Manufacturing</i>)</b></p>
              <p>
                Customer was experiencing high file access activity on some internal servers and
                users were having difficulty accessing internal servers. Provided high level
                analysis, data gathering, and management to the team tasked with solving this
                problem. The analysis of the process indicated that multiple client systems were
                performing large scans of internal file servers. The issue was determined to be a
                zero-day virus. The epidemiology of the virus was learned through analysis of
                logs, processes, and network traffic. To help mitigate the negative effects of the
                virus, a honey-pot server was setup and helped relieve file access activity on the
                internal servers. This provided much needed time to work with the anti-virus
                software company for development of new virus definitions.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2003 - Present</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Adjunct Lecturer</h3>
              <div className="subheading mb-3">Illinois State University</div>
              <p>
                Responsibilities include in-class lecture, preparing lab materials, assisting
                students during hands-on lab exercises, use of online learning management
                system, assess student success, and provide extra assistance and support to
                students as needed.
              </p>
              <p><u>COURSES TAUGHT</u></p>
              <ul>
                <li>Software Quality Assurance and Testing (Graduate Level)</li>
                <li>Wireless Communication Systems (Upper Level Undergraduate)</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2017-2018</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Adjunct Professor</h3>
              <div className="subheading mb-3">Robert Morris University</div>
              <p>
                Instructor of various computer science courses including Linux, HTML, XML,
                Multimedia, Windows Server, Microsoft Exchange, Firewall, and VPN
                technologies. Responsibilities include in-class lecture, oversee and assist students
                with hands-on lab activities, assess student success, and provide students with
                extra assistance as needed.
              </p>
              <p><u>COURSES TAUGHT</u></p>
              <ul>
                <li>Linux/Unix</li>
                <li>Local Area Networking</li>
                <li>JAVA Programming</li>
                <li>Microsoft SQL Server Administration</li>
                <li>Advanced Network Services</li>
                <li>XML and Programming with XML</li>
                <li>Web Development (HTML and CSS)</li>
                <li>Computer Forensics</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2003 - 2012</span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="education"
      >
        <div className="w-100">
          <h2 className="mb-5">Education</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">University of Illinois Chicago</h3>
              <div className="subheading mb-3">in progress - PhD, Computer Science</div>
              <p>
                Actively pursuing this degree with coursework and research in software quality
                assurance (see Research section).  Courework completed includes the areas of
                Artificial Intelligence, Emperical Methods in Human Centered Computing,
                Data and Web Semantics, Clouding Comuting Objects, and BioE Biocontrol.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">August 2018 - Present</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">University of Illinois at Springfield</h3>
              <div className="subheading mb-3">Master of Science, Computer Science</div>
              <p>
                Classes included various computer topics including databases, programming, software
                engineering, interface design, network programming (UNIX and C), Distributed Systems
                (JAVA and C++), and research on various emerging technologies.
              </p>
              <p>
                <u>Research Seminar: Literary Review:</u> Researched proposed design techniques of the
                semantic web. Topics included syntactic/semantic definition, service discovery, and
                overall quality of data.
              </p>
              <p>
                <u>Research Seminar: Simulation:</u> The research seminar course required the development
                of a simulation and reporting on the results of the simulation. The simulation emulated
                the card game War. Typically, the game of War continues for a long time, so the proposed
                topic question was “How often do never ending games of the card game War occur?”
                The simulation was designed to randomize the deck, determine the winner of a hand,
                track each player’s deck, and detecting a repeating deck pattern (never ending game
                detection). The outcome of the simulation showed a high occurrence of never ending
                games, which may be skewed based on the ruleset used for the game (no reshuffle at end
                of a player’s deck) and removal of the human element (unintentional reordering of cards
                placed at bottom of player’s deck).
              </p>
              <p>
                <u>Teaching:</u> Led a classroom demonstration on the Oracle DBMS for a course on
                Advanced Databases. The course focused on Microsoft SQL and the instructor wanted to
                expose the students to Oracle as well. My undergraduate coursework and professional
                experience provided me the ability to inform the class on the basics of the Oracle DBMS.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Completed December 2008</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">Benedictine University</h3>
              <div className="subheading mb-3">Master of Business Administration</div>
              <p>
                Coursework delivered using Next Generation Learning that uses a blended approach that
                uses both online and face-to-face sessions. The MBA program focuses on problem-based
                learning that combines quantitative and qualitative courses that prepare for the demands
                of contemporary business leadership in a complex, uncertain environment.
              </p>
              <p>
                <u>Entrepreneurship:</u> Developed full business plan and presentation for a movie theater
                with restaurant and bar to provide a unique entertainment experience within the
                Springfield, IL MSA. The plan included research of market trends, budgeting, location,
                marketing, financing, and operations to provide a comprehensive guide for building a
                successful business.
              </p>
              <p>
                <u>Strategic Management:</u> To prove the skills obtained throughout the MBA program, each
                student participated in the Business Strategy Game (BSG). The class was divided into
                teams and each team represented a company in the shoe manufacturing industry. The
                goal was to obtain the highest overall score, which comprised of common business
                metrics. Using strategic thinking our team was able to consistently outperform our
                competition by accurately producing needed supply, focusing on product quality, and
                competitively pricing our products. By reducing overall manufacturing costs, our team
                was able to undercut competitors while maintaining consistent profit margins. Our
                strategy resulted in achieving first place in our class.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">April 2016 - November 2017</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Robert Morris University</h3>
              <div className="subheading mb-3">B.A.S., Computer Studies</div>
              <p>
                Studied various network technologies and software application development, and
                business-related coursework. Topics included Windows Server/Desktop, Linux
                Server/Desktop, CISCO Routing/Switching, Oracle Database, C++ programming, JAVA
                application development, Software Testing, Web Design (HTML, CSS, and JavaScript),
                and Business/Management.
              </p>
              <p>
                <u>Solitaire Encryption:</u> Class project involved implementation of Bruce Schneier’s
                Solitaire encryption algorithm as a C++ console application.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">September 1999 - February 2002</span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="research"
      >
        <div className="w-100">
          <h2 className="mb-5">Research</h2>

          <ul>
            <li>Exploration of bug detection using variable taints (on going).</li>
          </ul>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="interests"
      >
        <div className="w-100">
          <h2 className="mb-5">Interests</h2>
          <ul>
            <li>Software Quality Assurance</li>
            <li>Testing Frameworks</li>
            <li>Entrepreneurship</li>
            <li>Artificial Intelligence</li>
            <li>Technology Applications in Medicine</li>
            <li>Internet of Things</li>
            <li>Sensor Networks</li>
            <li>Entrepreneurship</li>
            <li>Semantic Web</li>
            <li>Project Management</li>
            <li>Leadership</li>
          </ul>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="awards"
      >
        <div className="w-100">
          <h2 className="mb-5">Industry Affiliations, Awards &amp; Certifications</h2>
          <ul>
            <li>Member, Association for Computing Machinery (2003-present)</li>
            <li>Member, Greater Springfield Chamber of Commerce</li>
            <li>Microsoft Certified Systems Engineer (MCSE) - certified since 2000</li>
            <li>CompTIA A+ certified since 2000</li>
            <li>CompTIA Network+ certified since 2001</li>
            <li>CompTIA I-Net+ certified since 2001</li>
          </ul>
        </div>
      </section>
    </div>
  </Layout>
);

export default IndexPage;
